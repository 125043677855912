<template>
  <div class="container layout-container">
    <div class="layout-bar">

    </div>
    <div class="layout-content" ref="container">
      <el-table :data="list" :height="height" v-loading="loading" border style="width: 100%">
        <el-table-column prop="id" label="id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :filterable="true" :filter-value="filters.id" :column="scope.column" :order="order" @order="onorder" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="name" label="名称" min-width="140" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.name" :column="scope.column" @filter="onfilter" />
          </template>

          <template v-slot:default="scope">
            <div class="table-link el-button el-button--text is-link" @click="toTrack(scope.row)">{{scope.row.name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.status" :column="scope.column"
              input-type="options" :options="statusOpts"
              @filter="onfilter"
            />
          </template>
          <template v-slot:default="scope">
            <div :class="`status-${scope.row.status}`">{{scope.row.statusText}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="host_id" label="主播id" width="80" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.host_id" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="host_name" label="主播名称" width="140" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.host_id" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="category" label="分类" width="80" align="center">
          <template v-slot:header="scope">
            <filterable
              :filterable="true" :filter-value="filters.category"
              :options="categories" input-type="options"
              :column="scope.column" @filter="onfilter"
            />
          </template>
        </el-table-column>
        <el-table-column prop="short_intro" label="一句话介绍" width="90" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.short_intro" class="item" :content="scope.row.short_intro">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="长介绍" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.intro" class="item" :content="scope.row.intro">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="cover" label="封面" width="100" align="center">
          <template v-slot:default="scope">
            <img v-viewer class="headimg" :src="scope.row.cover" v-if="scope.row.cover" />
          </template>
        </el-table-column>
        <el-table-column prop="tags" label="标签" min-width="80" align="center"></el-table-column>
        <el-table-column prop="age" label="年龄段" min-width="120" align="center"></el-table-column>
        <el-table-column prop="grade" label="年级" width="80" align="center"></el-table-column>
        <el-table-column prop="subject" label="学科" width="60" align="center"></el-table-column>
        <el-table-column prop="publisher" label="教材版本" width="80" align="center"></el-table-column>
        <el-table-column prop="source_id" label="来源id" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.source_id" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="source_from" label="来源" width="100" align="center">
          <template v-slot:header="scope">
            <filterable :filterable="true" :filter-value="filters.source_from" :column="scope.column" @filter="onfilter" />
          </template>
        </el-table-column>
        <el-table-column prop="intro" label="关联单曲" width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" class="item">
              <template #reference>
                <el-button link type="primary" @click="loadSongs(scope.row)">关联单曲</el-button>
              </template>

              <div class="songs-list" v-if="scope.row.songs">
                <div v-for="(song, inx) in scope.row.songs" class="songs-list-item">{{song.name}}</div>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_title" label="ES标题" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_title" class="item" :content="scope.row.es_title">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_keywords" label="ES关键字" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_keywords" class="item" :content="scope.row.es_keywords">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="es_description" label="ES描述" min-width="80" align="center">
          <template v-slot:default="scope">
            <el-popover width="500" trigger="click" v-if="scope.row.es_description" class="item" :content="scope.row.es_description">
              <template #reference>
                <el-button link type="primary">内容</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="更新时间" width="150" align="center">
          <template v-slot:header="scope">
            <filterable :orderable="true" :column="scope.column" :order="order" @order="onorder" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="layout-bbar">
      <page ref="page" :page="page.page" :size="page.size" @change="onpage" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import page from '@/components/page/index'
import filterable from '@/components/table/header.filterable.vue'

export default {
  components: { page, filterable },

  data() {
    return {
      height: '200px',
      list: [],
      dicts: null,
      categories: [],
      statusOpts: [
        { id: 0, title: '下架' },
        { id: 1, title: '上架' }
      ],
      loading: false,
    }
  },

  computed: {
    ...mapState({
      resized: state => state.runtime.resized,

      filters: state => state.albumTrack.filters,
      order: state => state.albumTrack.order,
      page: state => state.albumTrack.page,
    })
  },

  async mounted() {
    this.resize()
    await this.reloadDicts()
    this.reload()
  },

  methods: {
    async reloadDicts() {
      const { data } = await this.request({
        method: 'POST',
        url: '/api/admin/sys/dict/options',
        data: {
          keys: ['age_group', 'grade', 'subject', 'publisher']
        }
      })

      const tag = await this.reloadTags()
      const category = await this.reloadCategories()

      this.categories = category
      this.dicts = {
        ...data,
        tag,
        category
      }
    },

    async reloadTags() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/options/tags'
      })
      return data
    },

    async reloadCategories() {
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/options/categories'
      })
      return data
    },

    onpage({ page, size }) {
      this.$store.commit('albumTrack/page', { page, size })
      this.reload()
    },

    onorder({ orderBy, orderType }) {
      this.$store.commit('albumTrack/order', { orderBy, orderType })
      this.reload()
    },

    onfilter({ id, value }) {
      this.$store.commit('albumTrack/filter', { [id]: value })
      this.reload()
    },

    val2opt(category, vals) {
      if (!vals) return vals
      const dict = this.dicts[category]
      if (!dict) return vals

      return vals.map(val => {
        for (const opt of dict) {
          if (opt.id == val) return opt.title
        }

        return val
      }).join('|')
    },

    async reload() {
      try {
        this.loading = true
        const params = this.$refs.page.params({
          order_by: this.order.orderBy,
          order_type: this.order.orderType,
          ...this.filters
        })
        const { data } = await this.request({
          method: 'GET',
          url: '/api/admin/resource/album/list',
          params: params
        })

        this.$refs.page.update(data)
        for (const item of data.data) {
          item.created_at = dayjs(item.created_at).format('YYYY-MM-DD HH:mm')
          item.updated_at = dayjs(item.updated_at).format('YYYY-MM-DD HH:mm')

          item.category = this.val2opt('category', [item.category])
          item.tags = this.val2opt('tag', item.tags ? item.tags.split(',') : null)
          item.age = this.val2opt('age_group', item.age ? item.age.split(',') : null)
          item.grade = this.val2opt('grade', [item.grade])
          item.subject = this.val2opt('subject', [item.subject])
          item.publisher = this.val2opt('publisher', [item.publisher])
          item.statusText = item.status ? '上架' : '下架'
          item.songs = null
        }
        this.list = data.data
      } finally {
        this.loading = false
      }
    },

    async loadSongs(album) {
      if (album.songs) return
      const { data } = await this.request({
        method: 'GET',
        url: '/api/admin/resource/album/track/list',
        params: { id: album.id }
      })
      album.songs = data || []
    },

    resize() {
      this.height = this.$refs.container.clientHeight
    },

    toTrack(row) {
      this.$store.commit('tableTrack/clear')
      this.$store.commit('tableTrack/filter', { album_id: row.id })
      this.$router.push(`/p/resource/track/index`)
    }
  }
}
</script>

<style>
.headimg{
  max-width:60px;
  max-height:60px;
}

.songs-list{
  max-height:300px;
  overflow:auto;
}

.songs-list-item{
  line-height:20px;
}

.el-button.table-link{
  white-space:normal;
  font-size:12px;
  font-weight:normal;
}
</style>
